.section {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 5vh 0 0;
}
@media (min-width: 1330px) {
  .container {
    max-width: 1250px;
  }
}
@media (min-width: 1330px) {
  .container {
    max-width: 1250px;
  }
}
@media (min-width: 1150px) {
  .container {
    max-width: 1100px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
.container {
  width: 100%;
  margin: auto;
  max-width: 1450px;
  max-width: 92%;
}
