.tariff {
  padding: 60px 0;
}
@media screen and (max-width: 600px) {
  .tariff {
    padding: 40px 10px;
  }
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 42px;
  text-align: center;
  margin-bottom: 60px;
}
.tariff__wrapper {
  margin: auto;
  display: flex;
  justify-content: center;
}
.tariff__table {
  display: grid;
  gap: 15vw;
  grid-template-columns: repeat(2, 20vw);
}
@media screen and (max-width: 1024px) {
  .tariff__table {
    grid-template-columns: repeat(1, 1fr);
    place-items: center;
  }
}
@media screen and (max-width: 600px) {
  .tariff__table {
    grid-template-columns: repeat(1, 1fr);
  }
}

.tariff-card {
  width: 100%;
  border: 1px solid #e6e6e5;
  border-radius: 10px;
  overflow: hidden;
}
@media screen and (max-width: 1024px) {
  .tariff-card {
    width: 478px;
  }
}
@media screen and (max-width: 780px) {
  .tariff-card {
    width: 100%;
  }
}
.tariff-card__header {
  padding: 12px 16px;
  height: 236px;
  background-color: #ffffff;
  border-bottom: 1px solid #ccdcff;
}
@media screen and (max-width: 780px) {
  .tariff-card__header {
    height: 140px;
  }
}
@media screen and (max-width: 600px) {
  .tariff-card__header {
    height: fit-content;
  }
}

.tariff-card__header-subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0;
  color: #37352f;
}
.tariff-card__body-subtitle a {
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #73716e;
  text-decoration: none !important;
  border-bottom: 2px solid #37352f;
  transition: color 0.3s ease-in-out;
}
.tariff-card__body-opportunity {
  height: 120px;
}
.tariff-card__body-title {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #37352f;
  margin: 0%;
}
.tariff-card__body-subtitle {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 0;
  color: rgba(0, 0, 0, 0.6);
}
.tariff-card__body-subtitle span {
  font-weight: 700;
}
.tariff-card__body-button_white {
  background-color: #fcfbfa;
  color: #37352f;
}
.tariff-card__body-button {
  margin: 20px auto 28px auto;
  width: 100%;
  height: 46px;
  border-radius: 10px;
  border: 1px solid #dfdbd7;
  text-align: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  margin-bottom: 0%;
  position: relative;
}

.tariff-switch__button-active span {
  display: block;
  position: absolute;
  top: 0;
  border-radius: 20px;
  background-color: #3b82f6;
  width: 100px;
  height: 46px;
  z-index: 2;
}
.tariff-card__body-features li p {
  margin: 0px 8px 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #37352f;
  list-style: none;
}
.tariff-card__body-features {
  padding: 0%;
}
.tariff-card__body-features li {
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  display: flex;
}
.tariff-card__header-wrapper {
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 1024px) {
  .tariff-card__header-wrapper {
    height: 44px;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }
}
.tariff-card__header-icon {
  margin-top: 12px;
  margin-bottom: 12px;
  font-size: 32px;
  line-height: 32px;
}
.emoji {
  font-family: "Apple Color Emoji";
}
.tariff-card__header-title {
  height: 80px;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #37352f;
  margin-bottom: 0;
}
.tariff-card__header-title_yellow {
  color: #ffbb33;
}
.tariff-card__header-title_red {
  color: #ea4e43;
}
.tariff-card__header-title_violet {
  color: #9d34da;
}
.tariff-card__header-subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0;
  color: #37352f;
}
.tariff-card__body {
  padding: 12px 16px 40px 16px;
  background-color: #f6f3f1;
  height: 100%;
  padding-bottom: 300px;
}
.tariff-card__body-opportunity {
  height: 120px;
}
@media screen and (max-width: 1024px) {
  .tariff-card__body-opportunity {
    height: 108px;
  }
}
.tariff-card__body-title {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #37352f;
}
.tariff-card__body-features li p a {
  font-weight: 600;
  text-decoration: underline;
  color: #73716e;
  transition: color 0.3s ease-in-out;
}
