*,
*::before,
*::after {
  box-sizing: inherit;
}
.header {
  position: sticky;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 56px;
  opacity: 1;
  display: flex;
  align-items: center;
  z-index: 100;
  background-color: #ffffff;
  border-bottom: 1px solid #e5e7eb;
  padding: 10px 0;
}

@media screen and (max-width: 1024px) {
  .header {
    padding: 8px 20px;
    background-color: #ffffff;
    border-bottom: 1px solid #ccdcff;
  }
}
@media screen and (max-width: 780px) {
  .header {
    height: -moz-fit-content;
    height: fit-content;
    padding: 0%;
    justify-content: space-between;
  }
}
.header-logo {
  cursor: pointer;
  align-items: center;
  display: flex;
}
.header-logo__img {
  width: 50px;
  height: 30px;
}
.header__text {
  font-size: 16px;
  font-weight: 600;
  font-family: "SF", "Inter-fallback", Helvetica, "Apple Color Emoji",
    "Segoe UI Emoji", "NotoColorEmoji", "Noto Color Emoji", "Segoe UI Symbol",
    "Android Emoji", "EmojiSymbols", -apple-system, "BlinkMacSystemFont",
    "Segoe UI", "Roboto", "Helvetica Neue", "Noto Sans", sans-serif,
    "Apple Color Emoji";
  margin: 0;
  text-decoration: none;
  white-space: nowrap;
  color: #37352f;
}
.header-navigation {
  display: block;
  margin: auto;
}
@media screen and (max-width: 1024px) {
  .header-navigation {
    display: none;
  }
}
.header-navigation__list {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin: 0;
  list-style: none;
  padding: 0%;
  gap: 4vw;
}
@media screen and (max-width: 1200px) {
  .header-navigation__list {
    max-width: 992px;
  }
}
.header-navigation__list-item {
  white-space: nowrap;
  margin: 0px 24px;
  padding: 0%;
}
.navigation-button {
  width: fit-content;
  height: 100%;
  font-style: normal;
  font-family: "SF", "Inter-fallback", Helvetica, "Apple Color Emoji",
    "Segoe UI Emoji", "NotoColorEmoji", "Noto Color Emoji", "Segoe UI Symbol",
    "Android Emoji", "EmojiSymbols", -apple-system, "BlinkMacSystemFont",
    "Segoe UI", "Roboto", "Helvetica Neue", "Noto Sans", sans-serif,
    "Apple Color Emoji";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  background-color: inherit;
  color: #73716e;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
  border: none;
  margin: 0%;
}
.button-key {
  background-color: #3b82f6;
  border-radius: 0.5rem;
  width: 130px;
  height: 36px;
  padding: 8px 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  font-style: normal;
  font-family: "SF", "Inter-fallback", Helvetica, "Apple Color Emoji",
    "Segoe UI Emoji", "NotoColorEmoji", "Noto Color Emoji", "Segoe UI Symbol",
    "Android Emoji", "EmojiSymbols", -apple-system, "BlinkMacSystemFont",
    "Segoe UI", "Roboto", "Helvetica Neue", "Noto Sans", sans-serif,
    "Apple Color Emoji";
  text-align: center;
  vertical-align: middle;
  color: #ffffff;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
  outline: none;
  border: none;
  margin-right: 20px;
}

@media screen and (max-width: 1024px) {
  .header__button {
    display: none;
  }
}
.btn-menu:hover {
  opacity: 0.5;
}
.btn-menu {
  display: none;
  position: fixed;
  right: 20px;
  top: 6px;
  width: 24px;
  height: 40px;
  border-color: transparent;
  background-color: transparent;
  transition: opacity 0.3s ease-in-out;
  cursor: pointer;
}
@media screen and (max-width: 1024px) {
  .btn-menu {
    display: inline-block;
  }
}
.header-mobile {
  display: flex;
  position: sticky;
  position: absolute;
  width: 100%;
  padding: 0%;
  background-color: #ffffff;
  top: 51px;
  left: 0;
  z-index: 10;
  border-bottom: 1px solid #e5e7eb;
}
.header-mobile__close {
  display: none;
}
.exit-button {
  display: none;
  position: fixed;
  top: 12px;
  right: 16px;
  width: 28px;
  height: 28px;
  border-color: transparent;
  background-color: transparent;
  transition: opacity 0.3s ease-in-out;
  cursor: pointer;
}
.exit-button__open {
  display: inline-block;
}
.btn-menu span:nth-of-type(1) {
  top: 28%;
}

.btn-menu span {
  display: none;
  position: absolute;
  width: 100%;
  height: 2px;
  border-radius: 2px;
  background-color: #000000;
}
.btn-menu span:nth-of-type(2) {
  top: 48%;
}
.btn-menu span:nth-of-type(3) {
  top: 68%;
}
@media screen and (max-width: 1024px) {
  .btn-menu span {
    display: block;
  }
}
.exit-button span:nth-of-type(1) {
  transform: rotate(45deg);
}
.exit-button span {
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  border-radius: 2px;
  background-color: #adada7;
  top: 50%;
  left: 0;
}
.exit-button span:nth-of-type(2) {
  transform: rotate(-45deg);
}
.header-mobile__list-item {
  padding: 10px 20px 20px;
  white-space: nowrap;
}
.header-mobile__list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0%;
  margin: 0px 0px 20px;
}
.hamburger-react{
  display: none;
}
@media screen and (max-width: 1024px) {
  .hamburger-react{
    display: block;
  }
}