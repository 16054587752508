body {
  margin: 0;
  max-width: 3840px;
  scroll-behavior: smooth;
  font-family: "SF", "Inter-fallback", Helvetica, "Apple Color Emoji",
    "Segoe UI Emoji", "NotoColorEmoji", "Noto Color Emoji", "Segoe UI Symbol",
    "Android Emoji", "EmojiSymbols", -apple-system, "BlinkMacSystemFont",
    "Segoe UI", "Roboto", "Helvetica Neue", "Noto Sans", sans-serif,
    "Apple Color Emoji";
  text-decoration: none;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}
html {
  scroll-behavior: smooth;
}

li {
  list-style: none;
}
p {
  text-decoration: none;
}
