@font-face {
    src: url(./Web/Inter-Regular.woff2) format("woff2"),
        url(./Web/Inter-Regular.woff) format("woff");
    font-family: "Inter";
    font-weight: 400;
}

@font-face {
    font-family: "Inter";
    src: url(./Web/Inter-Medium.woff2) format("woff2"),
        url(./Web/Inter-Medium.woff) format("woff");
    font-weight: 500;
}

@font-face {
    font-family: "Inter";
    src: url(./Web/Inter-Black.woff2) format("woff2"),
        url(./Web/Inter-Black.woff) format("woff");
    font-weight: 900;
}

@font-face {
    font-family: "OpenSans";
    src: local('OpenSans'),
     url(./Web/OpenSans.ttf) format("truetype");
    font-weight: 400;
}

@font-face {
    font-family: "Teko";
    src: url(./Web/Teko-Regular.ttf) format("truetype");
    font-weight: 400;
}

@font-face {
    font-family: "Teko";
    src: url(./Web/Teko-Medium.ttf) format("truetype");
    font-weight: 500;
}

@font-face {
    font-family: "Teko";
    src: url(./Web/Teko-Light.ttf) format("truetype");
    font-weight: 300;
}

@font-face {
    font-family: "Teko";
    src: url(./Web/Teko-Bold.ttf) format("truetype");
    font-weight: 900;
}


@font-face {
	font-family: 'SF';
	src: url('./Web/sf_ui_display_black.eot'); /* IE 9 Compatibility Mode */
	src: url('./Web/sf_ui_display_black.eot?#iefix') format('embedded-opentype'), /* IE < 9 */
		url('./Web/sf_ui_display_black.woff2') format('woff2'), /* Super Modern Browsers */
		url('./Web/sf_ui_display_black.woff') format('woff'), /* Firefox >= 3.6, any other modern browser */
		url('./Web/sf_ui_display_black.ttf') format('truetype'), /* Safari, Android, iOS */
		url('./Web/sf_ui_display_black.svg#sf_ui_display_black') format('svg'); /* Chrome < 4, Legacy iOS */
        font-weight: 900;
}

@font-face {
	font-family: 'SF';
	src: url('./Web/FontsFree-Net-SF-UI-Display-Light.ttf'); /* IE 9 Compatibility Mode */
        font-weight: 400;
}