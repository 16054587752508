.footer {
  padding: 40px 60px;
  background-color: #f6f3f1;
  text-decoration: none;
}
@media screen and (max-width: 600px) {
  .footer {
    padding: 20px 20px 40px;
  }
}
.footer__wrapper {
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 0%;
  border-top: #37352f 1px;
}
@media screen and (max-width: 600px) {
  .footer__wrapper {
    flex-direction: column;
    align-items: flex-end;
  }
}

.footer__logo {
  cursor: pointer;
  align-items: center;
  display: flex;
  width: 90px;
  height: 50px;
  text-decoration: none;
}
.footer__logo-img {
  width: 90px;
  height: 50px;
}
@media screen and (max-width: 600px) {
  .footer__logo-img {
    width: fit-content;
  }
}
.footer__text {
  font-size: 16px;
  font-weight: 600;
  font-family: "SF", "Inter-fallback", Helvetica, "Apple Color Emoji",
    "Segoe UI Emoji", "NotoColorEmoji", "Noto Color Emoji", "Segoe UI Symbol",
    "Android Emoji", "EmojiSymbols", -apple-system, "BlinkMacSystemFont",
    "Segoe UI", "Roboto", "Helvetica Neue", "Noto Sans", sans-serif,
    "Apple Color Emoji";
  margin-bottom: 0;
  margin-left: 4px;
  text-decoration: none;
  color: #37352f;
}

.footer-documents__item:first-child {
  margin-top: 0;
}
.footer-documents__item p {
  text-align: left;
  text-transform: uppercase;
  text-decoration: none;
}
.footer-contacts {
  display: flex;
  flex-direction: column;
}

.footer-contacts a {
  margin-top: 12px;
  display: flex;
  align-items: center;
}
.footer-contacts a img {
  width: 40px;
  height: 40px;
  margin-right: 20px;
}
.footer-contacts a p {
  white-space: nowrap;
  margin-bottom: 0;
}
