.section__about {
  margin: 0% auto;
  max-width: 80%;
}
.main-presentation {
  margin: 40px 64px 0;
}
.logo {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}
@media screen and (max-width: 600px) {
  .logo {
    justify-content: flex-start;
  }
}
@media screen and (max-width: 600px) {
  .main-presentation {
    margin: 20px 0 0;
  }
}
.logo-image {
  width: 150px;
  height: 150px;
  margin-right: 20px;
}
@media screen and (max-width: 900px) {
  .logo-image {
    width: 70px;
    height: 70px;
    margin-right: 16px;
  }
}
@media screen and (max-width: 600px) {
  .logo-image {
    width: 60px;
    height: 60px;
  }
}
.logo-title {
  font-style: normal;
  font-weight: 700;
  font-size: 76px;
  line-height: 76px;
  margin: 0px;
  display: flex;
  justify-content: center;
}

.main-presentation__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.main-presentation__title {
  text-align: center;
  font-size: 76px;
  margin: 0%;
}
@media screen and (max-width: 900px) {
  .main-presentation__title {
    font-size: 52px;
    line-height: 52px;
  }
}
@media screen and (max-width: 600px) {
  .main-presentation__title {
    font-size: 40px;
    line-height: 40px;
  }
}

.main-presentation__description {
  margin-top: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
}
@media screen and (max-width: 600px) {
  .main-presentation__description {
    font-size: 20px;
    line-height: 28px;
  }
}
.main-presentation__order {
  padding: 40px 0;
  text-align: center;
}
.main-presentation__order-button {
  width: 192px;
  height: 47px;
  font-size: 22px;
}
@media screen and (max-width: 1200px) {
  .main-presentation__order-button {
    font-size: 18px;
    width: 180px;
    height: 60px;
  }
}
@media screen and (max-width: 900px) {
  .main-presentation__order-button {
    width: 100%;
    height: 36px;
    padding: 8px 0;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    margin: 0%;
  }
}
.title {
  font-family:
    "Segoe UI Emoji", "NotoColorEmoji", "Noto Color Emoji", "Segoe UI Symbol",
    "Android Emoji", "EmojiSymbols", -apple-system, "BlinkMacSystemFont",
    "Segoe UI", "Roboto", "Helvetica Neue", "Noto Sans", sans-serif,
    "Apple Color Emoji";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 42px;
  text-align: center;
}
@media screen and (max-width: 900px) {
  .main-advantages {
    margin-top: 0px;
  }
}
.main-advantages__title{
  font-family: "SF", "Inter-fallback", Helvetica, "Apple Color Emoji",
    "Segoe UI Emoji", "NotoColorEmoji", "Noto Color Emoji", "Segoe UI Symbol",
    "Android Emoji", "EmojiSymbols", -apple-system, "BlinkMacSystemFont",
    "Segoe UI", "Roboto", "Helvetica Neue", "Noto Sans", sans-serif,
    "Apple Color Emoji";
    display: flex;
    font-size: 29.2px;
}
.main-advantages__wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  margin: 40px 0px 0px;
}
@media screen and (max-width: 900px) {
  .main-advantages__wrapper {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    margin-top: 20px;
  }
}
@media screen and (max-width: 600px) {
  .main-advantages__wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}

.main-advantages__item {
  background-color: #f6f3f1;
  border-radius: 10px;
  border: 1px solid #e6e6e5;
  padding: 24px;
  display: flex;
  align-items: flex-start;
}
.main-advantages__item-image {
  font-size: 16px;
  line-height: 24px;
  margin: 0 8px 0 0;
}

.description-title {
  font-family: "SF", "Inter-fallback", Helvetica, "Apple Color Emoji",
    "Segoe UI Emoji", "NotoColorEmoji", "Noto Color Emoji", "Segoe UI Symbol",
    "Android Emoji", "EmojiSymbols", -apple-system, "BlinkMacSystemFont",
    "Segoe UI", "Roboto", "Helvetica Neue", "Noto Sans", sans-serif,
    "Apple Color Emoji";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin: 0%;
}
.description {
  font-family: "SF", "Inter-fallback", Helvetica, "Apple Color Emoji",
    "Segoe UI Emoji", "NotoColorEmoji", "Noto Color Emoji", "Segoe UI Symbol",
    "Android Emoji", "EmojiSymbols", -apple-system, "BlinkMacSystemFont",
    "Segoe UI", "Roboto", "Helvetica Neue", "Noto Sans", sans-serif,
    "Apple Color Emoji";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  margin-bottom: 0px;
}
